import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'

export const useAddToCartStore = defineStore({
    id: 'add_to_cart',
    state(){
        return {
          loading: false,
          addToCart: {
            enabled: false,
          },
          originalAddToCart: {},
        }
    },
    getters: {
      upsellAddToCartExampleProps() {
        return {
          productImage: 'https://cdn.shopify.com/s/files/1/0735/7290/9329/files/ALT-BSS-LB_default_28-04-2022-10-54.jpg',
          productTitle: 'Product title',
          title: this.addToCart?.title,
          contentTop: 'Get 5 USD off on Product title',
          contentMiddle: 'when you buy 2 more of Product title',
          titleStyle: this.titleStyle,
          contentTopStyle: this.contentTopStyle,
          contentMiddleStyle: this.contentMiddleStyle,
          addToCartButtonStyle: this.addToCartButtonStyle,
          skipOfferButtonStyle: this.skipOfferButtonStyle,
          quantityControlStyle: this.quantityControlStyle,
          showCopyStatusMessage: false,
        }
      },
      titleStyle() {
        return {
          fontSize: '5cqw',
          color: this.addToCart.title_color,
          margin: '12px',
        };
      },
      addToCartButtonStyle() {
        return {
          width: '50cqw',
          height: '13cqw',
          backgroundColor: this.addToCart.add_button_background_color,
          borderRadius: '60px',
          border: `3px solid ${this.addToCart.add_button_border_color}`,
          fontFamily: 'Circular Std',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '5cqw',
          textAlign: 'center',
          color: this.addToCart.add_button_font_color,
          marginRight: '12px',
        };
      },
      skipOfferButtonStyle() {
        return {
          width: '50cqw',
          height: '13cqw',
          backgroundColor: this.addToCart.skip_button_background_color,
          borderRadius: '60px',
          border: `3px solid ${this.addToCart.skip_button_border_color}`,
          fontFamily: 'Circular Std',
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '5cqw',
          textAlign: 'center',
          color: this.addToCart.skip_button_font_color,
        };
      },
      quantityControlStyle() {
        return {
          width: '130px',
          height: '36px',
          margin: 'auto',
          marginBottom: '12px',
          color: this.addToCart.quantity_box_color,
          borderColor: this.addToCart.quantity_box_color,
        };
      },
      contentTopStyle() {
        return {
          fontSize: '6cqw',
          color: this.addToCart.content_top_color,
          margin: '12px',
        };
      },
      contentMiddleStyle() {
        return {
          fontSize: '4cqw',
          color: this.addToCart.content_middle_color,
          marginBottom: '12px',
        }
      },
      mobileScreen() {
        return this.$mq === 'mobile';
      }
    },
    actions: {
      async loadAddToCart() {
          try {
              this.loading = true
              const add_to_cart = await this.$nuxt.$axios.$get(`${process.env.API_V1_URL}/upsells/add_to_cart/current`)
              this.setAddToCart(add_to_cart)
          } catch (err) {
            this.$nuxt.$toasts.add({
                type: 'error',
                title: 'Failed to fetch Add To Cart!',
                subtitle: error.response.data.errors.join(".\n"),
                error
            })
          } finally {
            this.loading = false
          }
      },
      async saveAddToCart() {
        try {
            this.loading = true
            const params = { add_to_cart: { ...this.addToCart } }
            const add_to_cart = await this.$nuxt.$axios.$patch(`${process.env.API_V1_URL}/upsells/add_to_cart/current`, params)
            this.setAddToCart(add_to_cart)
            this.$nuxt.$toasts.add({
                type: 'success',
                title: 'Upsell updated!',
                subtitle: `Your settings were successfully updated`
            })
        } catch (err) {
            this.$nuxt.$toasts.add({
                type: 'error',
                title: 'Failed to save Add to Cart!',
                subtitle: error.response.data.errors?.join(".\n"),
                bottomMessage: error.response.data.errors ? '' : 'Please try again later.',
            })
        } finally {
          this.loading = false
        }
      },
      setAddToCart(newAddToCart) {
        this.addToCart = {
          ...newAddToCart.data
        }
        this.originalAddToCart = cloneDeep(this.addToCart)
      }
    }
})
